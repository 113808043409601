<template>
  <modal name="SignUpProviderPopup" transition="pop-out" @opened="opened">
    <div class="signUpProviderPopup" id="signUpProviderPopup">
      <div class="signUpProviderPopup__inner">
        <div class="signUpProviderPopup__title">New aircraft provider account</div>
        <div class="signUpProviderPopup__form">
          <form
            class="form form--signUpProvider js-ajaxForm"
            :class="{'loading' : loading}"
            data-href="#"
            data-action="signUpProvider"
            id="signUpProviderForm"
            @submit.prevent="submitHandler"
          >
            <Spinner/>
            <div class="form__labelGroup">
              <label class="form__label form__label--text">
                <div class="form__caption">Name</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    v-model="firstName"
                    autocomplete="off"
                    placeholder="First"
                    :disabled="loading"
                  />
                  <span
                  v-if="$v.firstName.$dirty && !$v.firstName.required"
                  class="form__field-invalid form__field-invalid--right-side"
                  style="color: #000"
                >
                  This field is required
                </span>
                </div>
              </label>
              <label class="form__label form__label--text">
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    v-model="middleName"
                    autocomplete="off"
                    placeholder="Middle"
                    :disabled="loading"
                  />
                  <span
                  v-if="$v.middleName.$dirty && !$v.middleName.required"
                  class="form__field-invalid form__field-invalid--right-side"
                  style="color: #000"
                >
                  This field is required
                </span>
                </div>
              </label>
              <label class="form__label form__label--text">
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    v-model="lastName"
                    autocomplete="off"
                    placeholder="Last"
                    :disabled="loading"
                  />
                  <span
                  v-if="$v.lastName.$dirty && !$v.lastName.required"
                  class="form__field-invalid form__field-invalid--right-side"
                  style="color: #000"
                >
                  This field is required
                </span>
                </div>
              </label>
            </div>
            <label class="form__label form__label--text">
              <div class="form__caption">Company</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="company"
                  autocomplete="off"
                  placeholder="Company name"
                  :disabled="loading"
                />
                <span
                v-if="$v.company.$dirty && !$v.company.required"
                class="form__field-invalid form__field-invalid--right-side"
                style="color: #000"
              >
                This field is required
              </span>
              </div>
            </label>
            <label class="form__label form__label--text phone">
              <div class="form__caption">Phone</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="phone"
                  autocomplete="off"
                  placeholder="+1 (___) ___-__-__"
                  v-mask="'+1 (###) ###-##-##'"
                  :disabled="loading"
                />
                <span
                v-if="$v.phone.$dirty && !$v.phone.required"
                class="form__field-invalid form__field-invalid--right-side"
                style="color: #000"
              >
                This field is required
              </span>
              </div>
            </label>
            <label class="form__label form__label--text">
              <div class="form__caption">Email</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="email"
                  v-model="email"
                  autocomplete="off"
                  placeholder="Email address"
                  :disabled="loading"
                />
                <span
                v-if="$v.email.$dirty && !$v.email.required"
                class="form__field-invalid form__field-invalid--right-side"
                style="color: #000"
              >
                This field is required
              </span>
              <span
                v-if="$v.email.$dirty && !$v.email.email"
                class="form__field-invalid form__field-invalid--right-side"
                style="color: #000"
              >
                Enter a valid Email
              </span>
              </div>
            </label>
            <div class="form__label form__label--button">
              <button class="form__button" :disabled="loading">
                <span class="text">Create</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <button
        @click="$modal.hide('SignUpProviderPopup')"
        class="fancybox-button fancybox-close-small"
      ></button>
    </div>
  </modal>
</template>

<script>
  import { email, required } from 'vuelidate/lib/validators'
  import Spinner from '@/components/common/Spinner/Spinner'

  export default {
    name: 'SignUpProviderPopup',
    data: () => ({
      firstName: '',
      middleName: '',
      lastName: '',
      company: '',
      phone: '',
      email: '',
      loading: false,
    }),
    validations: {
      firstName: { required },
      middleName: { required },
      lastName: { required },
      company: { required },
      phone: { required },
      email: {
        email,
        required,
      },
    },
    methods: {
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const container = document.querySelector('.vm--container')
          document.querySelector('body').appendChild(container)
        }
      },
      submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        this.loading = true

        const data = {
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          company: this.company,
          phone: this.phone,
          email: this.email,
        }

        this.$store.dispatch('createNewProvider', data)
        this.loading = false;
      },
    },
    components: {
      Spinner,
    },
  }
</script>
