<template>
  <modal name="BookingDetailsPopup" transition="pop-out" @opened="opened" @before-open="beforeOpen">
    <div class="requestsDashboardDetail">
      <div
        class="requestsDashboardDetail__inner"
      >
        <div class="requestsDashboardDetail__title">BOOKING DETAILS</div>
        <div class="requestsDashboardDetail__info" v-if="additionalInfo">
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Tail Number</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.aircraft.tailNumber}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Aircraft</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.model.name}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Aircraft Provider</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.provider.companyName}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Price and/or Price Per Seat</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.booking.totalExclusiveCost}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Departure Airport</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.departure.displayName}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Arrival Airport</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.arrival.displayName}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">RT/OW</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.booking.direction == 1 ? 'RT' : 'OW'}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Date(s)</div>
            <div class="requestsDashboardDetail__info-item-value">{{moment(additionalInfo.bookingFlights[0].flight.departureDate).format('MM/DD/YYYY')}} <span v-if="additionalInfo.booking.direction == 1">{{" - " + moment(additionalInfo.bookingFlights[1].flight.departureDate).format('MM/DD/YYYY')}}</span> </div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Distance</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.bookingFlights[0].flight.distance}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Duration</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.bookingFlights[0].flight.duration}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">PAX</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.booking.numPax}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Total PAX</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.aircraft.maxPassengers - additionalInfo.flight.numberOfSeatsAvailable}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Max PAX</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.aircraft.maxPassengers}}</div>
          </div>
        </div>
      </div>

      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('BookingDetailsPopup')"></button>
    </div>
  </modal>
</template>

<script>
  import moment from 'moment'
  import './RequestsDetailPopup.styl'

  export default {
    name: 'BookingDetailsPopup',
    props: ['justInfo'],
    data: () => ({
      type: null,
      additionalInfo: null,
    }),
    methods: {
      moment,
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const container = document.querySelector('.vm--container')
          document.querySelector('body').appendChild(container)
        }
      },
      beforeOpen (event) {
        this.additionalInfo = this.$store.state.booking.bookingDetails
      },
      showConfirmation () {
        this.$modal.hide('FlyerDetailsPopup')
        this.$modal.show('questionPopupFlightRequest')
      },
      showBookingCreationForm () {
        this.$modal.hide('FlyerDetailsPopup')
        this.$modal.show('NewBookingPopup')
      },
    },
  }
</script>
