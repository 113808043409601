<template>
  <div
    class="bookingDashboard bookingDashboard--list"
    :class="'bookingDashboard--' + dashboardType"
    :key="currentPath"
  >
    <div class="bookingDashboard__header">
      <div class="bookingDashboard__title">{{ subtitle.replace('-', ' ') }}</div>
      <span
        v-if="dashboardType === 'provider'"
        class="buttonCreate"
        @click="$modal.show('SignUpProviderPopup')"
      >
        <span class="text">Create</span>
      </span>
      <router-link v-if="dashboardType === 'admin'" class="signUpDashboard__button" to="">
          <span
          @click="exportToExcel()"
          class="text">Export</span>
        </router-link>
    </div>
    <div class="bookingDashboard__table">
      <div class="bookingDashboard__table-header">
        <div class="bookingDashboard__table-cell">№</div>
        <div class="bookingDashboard__table-cell">Flyer name</div>
        <div class="bookingDashboard__table-cell">Booking number</div>
        <div class="bookingDashboard__table-cell">Booking date</div>
      </div>
      <div class="bookingDashboard__table-body js-customScroll">
        <vue-scroll :ops="ops">
          <div class="bookingDashboard__table-row" v-for="(item, index) of list" :key="index">
            <div class="bookingDashboard__table-cell">
              <div class="bookingDashboard__table-index">{{list.length - index}}</div>
            </div>
            <div class="bookingDashboard__table-cell">
              <a
                class="bookingDashboard__table-name"
                to=""
                @click="showFlyerDetailsPopup(item.flyer)"
              >
                {{item.flyer.firstName + " " + item.flyer.lastName}}
              </a>
            </div>
            <div class="bookingDashboard__table-cell">
              <span>{{item.number}}</span>
            </div>
            <div class="bookingDashboard__table-cell">
              {{moment(item.createdOn).format('MM/DD/YYYY')}}
              <a
                class="bookingDashboard__table-detail"
                to=""
                @click="showBookingDetailsPopup(item.id)"
              >click for details
              </a>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
    <SignUpProviderPopup v-if="dashboardType === 'provider'"/>
    <ComingSoonPopup/>
    <FlyerDetailsPopup/>
    <BookingDetailsPopup/>
  </div>
</template>

<script>
  import { reportsAPI } from '@/api/reports'
  import SignUpProviderPopup from '@/components/common/modals/SignUpProviderPopup'
  import ComingSoonPopup from '@/components/common/modals/ComingSoonPopup'
  import FlyerDetailsPopup from '@/components/common/modals/FlyerDetailsPopup'
  import BookingDetailsPopup from '@/components/common/modals/BookingDetailsPopup'
  import moment from 'moment'
  import '../../admin/SignUpDashboard/SignUpDashboard.styl'

  export default {
    props: ['dashboardType', 'subtitle'],
    name: 'BookingList',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      list () {
        return this.$store.state.booking.bookingsList
      },
      currentPath () {
        return this.$router.currentRoute.path
      },
    },
    components: {
      SignUpProviderPopup,
      ComingSoonPopup,
      FlyerDetailsPopup,
      BookingDetailsPopup,
    },
    mounted () {
      switch (this.$router.currentRoute.path) {
          case '/admin/reservations':
            this.$store.commit('setReservations');
            break;
          case '/admin/confirmed-reservations':
            this.$store.commit('setConfirmedReservations')
            break;
          case '/admin/bookings':
            this.$store.commit('setBookings')
            break;
          case '/admin/confirmed-bookings':
            this.$store.commit('setConfirmedBookings')
            break;
            default:
            return true;
        }
    },
    watch: {
      $route (to, from) {
        switch (this.$router.currentRoute.path) {
          case '/admin/reservations':
            this.$store.commit('setReservations');
            break;
          case '/admin/confirmed-reservations':
            this.$store.commit('setConfirmedReservations')
            break;
          case '/admin/bookings':
            this.$store.commit('setBookings')
            break;
          case '/admin/confirmed-bookings':
            this.$store.commit('setConfirmedBookings')
            break;
            default:
            return true;
        }
      },
    },
    methods: {
      moment,
      showFlyerDetailsPopup (account) {
        this.$modal.show('FlyerDetailsPopup', {additionalInfo: account})
      },
      async showBookingDetailsPopup (id) {
        await this.$store.dispatch('getBookingDetails', id)
        this.$modal.show('BookingDetailsPopup')
      },
      async exportToExcel () {
        let report;
        const reportType = this.$router.currentRoute.path.split('/').pop();

        switch (this.$router.currentRoute.path) {
          case '/admin/reservations':
            report = reportsAPI.reservations();
            break;
          case '/admin/confirmed-reservations':
            report = reportsAPI.confirmedReservations();
            break;
          case '/admin/bookings':
            report = reportsAPI.bookings();
            break;
          case '/admin/confirmed-bookings':
            report = reportsAPI.confirmations();
            break;
          default:
          return true;
        }

        const { data } = await report;

        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', data]);
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `${reportType}_report_` + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
    },
  }
</script>
